import req from '../api/https.js'

export const myLineService ={
    postdata,
    updata,
    finddata,
    menber,
    check,
    postpay,
    myorder,
}

function postdata(dev){
    return req('post','join/postdata',dev).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function postpay(dev){
    return req('post','pay/created ',dev).then(rs=>{    
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function updata(dev){
    return req('put','join/updata',dev).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })    
}


function finddata(page,size,id,mods){
    return req('get', 'join/Finddata/'+page+'/'+size+'/'+id+'/'+mods).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function myorder(id){
    return req('get', 'join/order/'+id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function menber(id){
    return req('get', 'join/menber/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function check(obj){
    return req('get', 'join/check/'+obj.uid+'/'+obj.fid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


