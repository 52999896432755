import axios from "axios";
import router from "../router/index";
import store from "../store/index"
import { tip, toLogin, to403Page } from "../api/utils";

const errorHandle = (status,msg) =>{
    switch(status){

        case 400: //請求錯誤
            tip(msg + "請求錯誤"); 
            break;
        
        case 401: //'未授權，請重新登入'
            if(router.currentRoute !== 'Login'){
                store.dispatch("account/logout")
            }
            tip(msg + "未授權，請重新登入");
            setTimeout(()=>{
                toLogin()
            },250);
            break;
        
        case 403: //拒绝訪問
            to403Page();
            break;

        case 404: //请求地址出錯:
            tip(msg + "請求地址出錯");
            break;

        case 408: //請求超時
            tip(msg + "請求超時");
            break   
            
        case 500: //服務器内部錯誤
            tip(msg  + "服務器内部錯誤");
            break  

        case 501: // 服務未實現
            tip(msg + "服務未實現");
            break     
            
        case 502: // 網路錯誤
            tip(msg + "網路錯誤");
            break                
        
        case 503: // 服務不可用
            tip(msg + "服務不可用");        
            break

        case 504: // 閘道超時
            tip(msg + "閘道超時");    
            break

        case 505: // HTTP版本不受支持
            tip(msg + "HTTP版本不受支持");             
            break

        default:
            tip('resp 沒有攔截到的錯誤訊息'+msg);



            
    }
}

var instance = axios.create({  
    baseURL: process.env.VUE_APP_URL
})

instance.interceptors.request.use((config)=>{
    const token = store.state.account.status.token; 
    token && (config.headers.Authorization = 'Bearer '+ token);
    return config;
    },(error)=>{
        return Promise.reject(error);
    })

instance.interceptors.response.use((response)=>{
    return response;
}, (error) => {
    const {response} = error;
    if(response){
        errorHandle(response.status, response.data.msg)
        return Promise.reject(error);
    } else {
        if(!window.navigator.onLine){
            tip('網路出了問題，請重新連線後刷新網頁');
        } else {
            return Promise.reject(error);
        }
    }
}
)


export default (method,url, data=null)=> {
    method = method.toLowerCase();
    if(method == 'post') {
        return instance.post(url, data)
    } else if (method == 'get') {
        if(data==null){
            return instance.get(url)
        }else{
            return instance.get(url,{params:data})
        }
    } else if (method == 'delete') {
        //return instance.delete(url,{params: data})
        //return instance.delete(url +"/"+ data)
        return instance.delete(url)
    } else if(method == 'put') {
        return instance.put(url, data)
    } else {
        tip('error method'+ method);
        return false
    }
}
