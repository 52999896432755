<template>
 <div>
    <b-form @submit.prevent="userLogin">
    <a-alert v-show="errors!==''" :message="errors" :type="mtyps" show-icon />
    <h6 class="card-title"><span style="color: rgb(206, 7, 7);"> "紅色欄位為必輸" </span></h6>
     <b-form-group id="input-group-1" label="請輸入手機號碼, 來做為帳號 :" :description="result? '該帳號已存在, 請使用其它帳號':result3?'請勿輸入中文字':''" label-for="input-1">
        <b-form-input
          id="input-1"
          v-model="user.account"
          placeholder="請輸入手機號碼, 來做為帳號"
          :state="nameState"
          aria-autocomplete="none"
          role="presentation"
          autocomplete="off"
          type="number"
          required
        ></b-form-input>
      </b-form-group>

     <b-form-group id="input-group-2" label="請輸入您的Email :" :description="result2? '該Email 已存在, 請使用其它email':''" label-for="input-2">
        <b-form-input
          id="input-1"
          v-model="user.email"
          placeholder="請輸入您的 Email"
          aria-autocomplete="none"
          role="presentation"
          autocomplete="off"
          type="email"
        ></b-form-input>
      </b-form-group>   

      <b-form-group id="input-group-3" label="請輸入密碼 :" label-for="input-3">
        <b-form-input
          id="input-3"
          v-model="user.password1"
          placeholder="請輸入您的密碼"
          type="password"
          :state="passwordState"
          aria-autocomplete="none"
          role="presentation"
          autocomplete="off"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="請輸入密碼 :" label-for="input-4">
        <b-form-input
          id="input-4"
          v-model="user.password2"
          placeholder="請確認您的密碼"
          type="password"
          :state="password2State"
          aria-autocomplete="none"
          role="presentation"
          autocomplete="off"
          required
        ></b-form-input>
      </b-form-group>  
      <b-button type="submit" squared variant="success" :disabled=" user.account==='' || user.password1==='' || user.password2==='' || user.password1!==user.password2 || display===true || result==true || result3==true" block>註冊</b-button>
    </b-form>
          <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
            <div class="text-center">
                <b-spinner  variant="primary" label="Text Centered"></b-spinner>
                <strong >Loading....</strong>
            </div>
          </b-modal> 
  </div>

</template>

<script>
import { userService } from '../_services/'
import {mapMutations} from 'vuex';

export default {
  name: 'Registered',
  data() {
    return {
        user: {
            account:"",
            email: "",
            hphone:"",
            password1: '',
            password2: '',
        }, 
        result:false, 
        result2:false, 
        result3:false,         
        display:false,
        errors:'',
        mtyps:'error',
    };
  },
  methods: {
    ...mapMutations('account',['setpage']),    
    async userLogin() {
            if(this.user.password1===this.user.password2)
            {
            this.display = true
            this.$refs['my-loading'].show()
            let tmp1 = this.user.account;
            let tmp2 = this.user.password1;
            let tmp3 = this.user.email;
            let tmp4 = this.user.account;            
            var playload ={
              password :  tmp2.toString().trim(),
              username :  tmp1.toString().trim(),
              utoken :'',
              email :tmp3.toString().trim(),
              address:'',
              birth: '',
              display_name : '',
              hphone : tmp4.toString().trim(),
              mphone : '',
              pri_url : '',
              sex:'',
              citys:'',
              villages:'',
              towns:'',             
            } 
            await userService.regist(playload)
              .then(
                  rs => {
                      if(rs.status===201){
                        this.$refs['my-loading'].hide()
                        this.$emit("change")
                      }                      
                  },
              );
            }else{
              this.errors="密碼不相符"
            }
    },

    onHide(evt) {
        if(evt.trigger === "backdrop"){
            evt.preventDefault();
            //this.handleBackdrop();
        }
    },

    async checkAccount (){
         let rsd =false
         if(this.user.account){
           rsd = await userService.checkAcct(this.user.account)
            .then(
                rs => {
                    return rs.data.verify
                },
            );
            this.result = rsd
         } 
         this.result = rsd
        },
    async checkemail (){
         let rsd =false
         if(this.user.email){
           rsd = await userService.checkEmail(this.user.email)
            .then(
                rs => {
                    return rs.data.verify
                },
            );   
         } 
         this.result2 = rsd
        },
        setResults(val){
          this.result3=val
        },
        setorgpage(obj){
          return new Promise((resolve)=>{  
            resolve(this.setpage(obj))            
          })          
    },         
      
  },
  watch:{
      //"user.email": function(newval, oldval){
      "user.email": function(){
        this.checkemail()
        this.result2===false ? true : false
      }
  },
    computed: {
      nameState() {
        var pattern = new RegExp("[\u4E00-\u9FA5]");
        if(pattern.test(this.user.account)){
          this.setResults(true)
          return false
        }else{
          this.checkAccount()
          this.setResults(false)
          return this.user.account.length > 0 && this.result===false ? true : false
        }
      },
      passwordState(){
        return this.user.password1.length > 0 ? true : false
      },
      password2State(){
        return this.user.password2.length > 0 && this.user.password1==this.user.password2 ? true : false
      },
    },
    mounted() {
      let obj= {id:'Home'}
      this.setorgpage(obj).then((val)=>{return val}).catch((val)=>{return val}).finally(() => {console.log('set page done')}) 
    }    
};
</script>