import { makorgService } from "../../_services/makorg.service";
import VueCookies from 'vue-cookies'

const state = {
    getrs:null,
    datas:[],
    defaultval : null,
    reloadItem : false,
    putdata:[],
    mylevel:null,
    mOrgIds:null, 
};

const mutations ={
    setrs(state,val){
        state.getrs = val
    },
    setdatas(state,val){
        VueCookies.remove('defaultval')
        if(val.data!==null){
            state.datas = val.data   
            if(val.data[0]!==undefined){
                let athority = val.data[0].acts_authority.find(rs => rs.Uid == val.id)
                state.defaultval = {name: val.data[0].name,id:val.data[0].id, state: val.data[0].state ,type:val.data[0].type ,permissions:athority}  
                let obj ={name: val.data[0].name,id:val.data[0].id,state: val.data[0].state,type:val.data[0].type}
                VueCookies.set('defaultval',obj);
            }
        }else{
            state.defaultval = {name: null,id:null, state: null,type:null}
            VueCookies.set('defaultval',state.defaultval);
        }        
     
    },
    setmylevel(state,val){
        state.mylevel = val;
    },

    updateorg(state,val){
        state.datas.find(item => item.id == val.id).state =val.state
    },
    
    setval(state,val){
        state.defaultval = val
    },

    setputdata(state,val){
        state.putdata = val 
    },

    setreloadItem(state,val){
        state.reloadItem = val
    },

    setMIDs(state,val){
        state.mOrgIds = val
    },    

    

}

const actions ={
    insertorg({commit},obj){
       return makorgService.orgwrite(obj)
        .then(
            rs =>{
                commit('setrs',rs);
                return rs
            }
        )
    },
    readorg({commit},obj){
        let ids = obj.id
        return makorgService.orgread(obj.id)
            .then(
                rs =>{
                    let val ={
                        data : rs,
                        id : ids
                    }
                    commit('setdatas',val)                  
                    return rs
                }
            )
    },

    getorglevel({commit},obj){
        let ids = obj.id
        return makorgService.findorglevel(ids)
            .then(
                rs =>{
                    let val ={
                        mydata:rs,
                    }
                    commit('setmylevel',val)                  
                    return rs
                }
            )        
    },

    readorgdata({commit},obj){
        return makorgService.readorgdata(obj.typname)
            .then(
                rs =>{
                    commit('setputdata',rs)                    
                    return rs
                }
            )
    },

    setorgval({commit},val){
        commit('setval',val)
    }
    
}

/*function revises(obj){
    let tmp = [];
    for(let i in obj){
      if(obj[i].vals!=='Chartreport'){
        if(obj[i].vals==='linecomment'){
          let Dtemp = obj[i].detail_permission.filter(rs => rs.vals =='Line_control')
          obj[i].detail_permission = Dtemp
          tmp.push(obj[i])
        }else{
          tmp.push(obj[i])
        }
      }
    }
    return tmp
  }*/

export const makeorg ={
    namespaced : true,
    state,
    mutations,
    actions
}