import req from '../api/https.js'

export const joinService ={
    postdata,
    updata,
    finddata,
    menber,
    check,
    postpay,
    myorder,
    sing,
    signin,
    singlist,
    singlistall,
    getsingMen,
    postform,
    postformm,
    getformm,
    singes,
    singmydate,
    signins,
    checkbyDate,
    myscores,
    getorder,
    queryJoin,
    postApply,
    getSummary,

}    

function postdata(dev){
    return req('post','join/postdata',dev).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function postform(dev){
    return req('post','joinform/postdata',dev).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function postformm(obj){
    return req('post','joinform/postdatam',obj).then(rs=>{        
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function getformm(obj){
    return req('get','joinform/getJoinFormany/'+obj.page+'/'+obj.id).then(rs=>{
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function queryJoin(id){
    return req('get','joinform/queryJoin/'+id).then(rs=>{
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function postpay(dev){
    return req('post','pay/created ',dev).then(rs=>{    
            return rs.data
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function updata(dev){
    return req('put','join/updata',dev).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })    
}


function finddata(page,size,id,mods){
    return req('get', 'join/Finddata/'+page+'/'+size+'/'+id+'/'+mods).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function myorder(id){
    return req('get', 'join/order/'+id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function menber(id){
    return req('get', 'join/menber/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function check(obj){
    return req('get', 'join/check/'+obj.uid+'/'+obj.fid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function checkbyDate(obj){
    return req('get', 'joinform/check/'+obj.uid+'/'+obj.fid+'/'+obj.aid).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function sing(obj){
    return req('get', 'sing/find/'+obj.id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function getSummary(){
    return req('get', '/sing/Summary').then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function signin(obj){
    return req('put','sing/update',obj).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function postApply(obj){
    return req('post','sing/postApply',obj).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function signins(obj){
    return req('put','sing/updates',obj).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function singlist(obj){
    return req('get', 'sing/orderlist/'+obj.page+'/'+obj.fid+'/'+obj.aid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function singlistall(obj){
    return req('get', 'sing/orderlistall/'+obj.page+'/'+obj.fid+'/'+obj.aid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function getsingMen(obj){
    return req('get', 'sing/getsingMen/'+obj.fid+'/'+obj.aid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function singes(obj){
    return req('get', 'singmy/find/'+obj.id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function singmydate(obj){
    return req('get', 'singmydate/find/'+obj.id+'/'+obj.myDate).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function myscores(obj){
    return req('get', 'singmys/find/'+obj.id+'/'+obj.date).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function getorder(obj){
    return req('get', 'sing/roles/'+obj.uid+'/'+obj.firstDate+'/'+obj.lastDate).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}


