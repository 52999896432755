<template>
 <div>
    <b-form @submit.prevent="userLogin">
      <a-alert v-show="errors!==''" :message="errors" :type="mtyps" show-icon />
      <b-form-group
        id="input-group-1"
        label="帳號"
        label-for="input-1"
        description=""
      >
        <b-form-input
          id="input-1"
          v-model="user.username"
          type="text"
          placeholder="請輸入 : 帳號"
          :state="nameState"
          aria-autocomplete="none"
          role="presentation"
          autocomplete="off"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="請輸入密碼 :" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="user.password"
          placeholder="請輸入您的密碼"
          type="password"
          :state="passwordState"
          autocomplete="new-password"
          required
        ></b-form-input>
      </b-form-group>

      <div class="form-group input-group">
              <input class="form-control" placeholder="請輸入驗證碼" style="height: 40px;" type="text" name="verify" v-model="verify" required>
              <div @click="refreshCode" style="cursor: pointer;">
                  <Identify :identifycode="identifyCode" ></Identify>
              </div>              
      </div>      

      <b-button type="submit" :disabled=" user.username==='' || user.password==='' || display===true || myCheck || identifyCode=='' " pill variant="info" block>帳號登入</b-button>
      <b-button type="button" pill variant="success" block @click="linelogin">  
        <img alt="Vue logo" src="../assets/line-loginicon.png">
        Line Login
      </b-button>
      <!--<b-button type="button" pill variant="primary" block @click="fblogin">
        <img src="../assets/facebook-loginicon.png" /> 
        Facebook Login
      </b-button>-->      

    </b-form>
          <b-modal ref="my-loading" id="modal-2" title="Loading" @hide="onHide" centered hide-footer hide-header>
            <div class="text-center">
                <b-spinner  variant="primary" label="Text Centered"></b-spinner>
                <strong >Loading....</strong>
            </div>
          </b-modal> 
  </div>

</template>

<script>
import {mapState,mapActions,mapMutations} from 'vuex';
import Identify from "../auth/Identify.vue";

export default {
  name: 'Login',
  props: {
    uname: String
  },  
  components: {
    Identify
  },  
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
        errors:'',
        mtyps:'error',
        display:false,
        verify:'',
        identifyCode: '',
        identifyCodes: '123456789abcdefghijklmnopqstuwxyzABCDEFGHGKMNPQRSTUVWXYZ',              
    };
  },
  methods: {
    ...mapActions('account',['login']),
    ...mapMutations('account',['setpage','setpageUrl']),
     userLogin() {
        if(this.user.username!=='' && this.user.password!==''){
         this.display = true
         this.$refs['my-loading'].show()
         let account={username:this.user.username,password:this.user.password}
         this.login(account)  
         let tmp =null
         if(this.uname=='Actinfo' || this.uname=='Outorg'){
           tmp =  { id: this.$route.params.id , name:this.$route.name,type:this.uname=='Outorg'?'N':'Y'}
         }          
          setTimeout(() => {
            if(this.status.token===''){
                this.$refs['my-loading'].hide()
                this.display = false
                if(localStorage.getItem('loginMsg')=='the user not exist'){
                  this.errors="該帳號不存在"
                }else if(localStorage.getItem('loginMsg')=='the user password wrong'){
                  this.errors="密碼錯誤, 請確認密碼輸入是否正確"
                }else{
                  this.errors=""
                }
            }else{
              if(this.uname=='Actinfo' || this.uname=='Outorg'){    
                this.setorgpageURL(tmp).then((val)=>{return val}).catch((val)=>{return val}).finally(() => {console.log('set page done')})                  
              }                        
            }                            
          }, 2500);
        }

    },

    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    
    makeCode(o, l) {
        for (let i = 0; i<l; i++) {
            this.identifyCode += this.identifyCodes[
                Math.floor(Math.random() * (this.identifyCodes.length - 0) + 0)
                ]
        }  
     },     

    setorgpage(obj){
          return new Promise((resolve)=>{  
            resolve(this.setpage(obj))            
          })          
    },   
    
    setorgpageURL(obj){
          return new Promise((resolve)=>{  
            resolve(this.setpageUrl(obj))            
          })          
    },     

    linelogin(){
      //window.location=process.env.VUE_APP_URL +'gotoauthOpenIDpage'
      let target = 'Home'
      if(this.targetpage){
        target = this.targetpage
      }
      if(this.uname!==''){
        let obj= {id:this.uname}
        if(this.uname=='Actinfo' || this.uname=='Outorg'){
          let tmp =  null
          if(this.uname=='Actinfo' || this.uname=='Outorg'){
            tmp =  { id: this.$route.params.id , name:this.$route.name,type:this.uname=='Outorg'?'N':'Y'}
          }                     
          this.setorgpageURL(tmp).then((val)=>{return val}).catch((val)=>{return val}).finally(() => {console.log('set page done')})            
          this.uname='Home'                
        }
        this.setorgpage(obj).then((val)=>{return val}).catch((val)=>{return val}).finally(() => {console.log('set page done')})                  
        target=this.uname
      } 
      //console.log("uname:",this.uname)     
      let  temp_form = document.createElement("form");
           temp_form.action = process.env.VUE_APP_URL +'gotoauthOpenIDpage';      
           temp_form.target = "_self";
           temp_form.method = "post";      
           temp_form.style.display = "none";
      let  opt = document.createElement("input"); 
           opt.type = "text";
           opt.name = "chatbot";
           opt.value = target
           temp_form.appendChild(opt);    
           document.body.appendChild(temp_form);
           // 提交表單      
           temp_form.submit();   
    },

    fblogin(){
      window.location=process.env.VUE_APP_URL +'fb'
    },
    onHide(evt) {
        if(evt.trigger === "backdrop"){
            evt.preventDefault();
            //this.handleBackdrop();
        }
    },

  },
    computed: {
      ...mapState('account',['status','targetpage']),
      nameState() {
        return this.user.username.length > 0 ? true : false
      },
      passwordState(){
        return this.user.password.length > 0 ? true : false
      },
      myCheck(){
        return this.verify.toLowerCase()!=this.identifyCode.toLowerCase()?true:false
      }
      
    },
    async mounted() {
      this.refreshCode();
    }    


};
</script>