import { macService } from "../../_services/mac.service";

const state = {
    getrs:null,
    datas:[]
};

const mutations ={
    setrs(state,val){
        state.getrs = val
    },
    setdatas(state,val){
        state.datas = val
    }
}

const actions ={
    insertmact({commit},obj){
       return macService.macwrite(obj)
        .then(
            rs =>{
                commit('setrs',rs);
                return rs
            }
        )
    }, 

    insertmactmp({commit},obj){
        return macService.macWriteTmp(obj)
         .then(
             rs =>{
                 commit('setrs',rs);
                 return rs
             }
         )
     }, 
     
     
     updatetmactmp({commit},obj){
        return macService.macUpdateTmp(obj)
         .then(
             rs =>{
                 commit('setrs',rs);
                 return rs
             }
         )
     }, 

     getTmpAct({commit},obj){
        return macService.getTmpActData(obj.id)
         .then(
             rs =>{
                 commit('setrs',rs);
                 return rs
             }
         )
     }, 
    readmact({commit},obj){
        return macService.macfind(obj.id)
            .then(
                rs =>{
                    commit('setdatas',rs)                    
                    return rs
                }
            )
    },
    
}

export const mact ={
    namespaced : true,
    state,
    mutations,
    actions
}