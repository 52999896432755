import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import {ClientTable} from 'vue-tables-2';
import {BootstrapVue,BootstrapVueIcons}  from 'bootstrap-vue'
import axios from 'axios'
import InfiniteLoading from 'vue-infinite-loading';
import VueAxios from 'vue-axios'
import VueJWT from 'vuejs-jwt'
import draggable from 'vuedraggable'
import 'ant-design-vue/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import * as moment from "moment/moment";
import VueCookies from 'vue-cookies'
//import VueHtml2pdf from 'vue-html2pdf'
import VueSocialSharing from 'vue-social-sharing'
import html2pdf from 'html2pdf.js'
import VueMeta from 'vue-meta';
import mDatePicker from 'vue-multi-date-picker'



Vue.use(Antd);
Vue.use(VueJWT);
Vue.use(VueCookies)
Vue.use(VueSocialSharing);
Vue.use(VueMeta);
Vue.use(mDatePicker)
//Vue.use(VueHtml2pdf)
Vue.use(html2pdf)
Vue.use(InfiniteLoading);
Vue.use(moment);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBVmtFPjBWxyeCTibks6nRitx7N3wYHCJc',
    libraries: ['geometry']
  }
});
Vue.use(VueAxios, axios)
Vue.use(draggable)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ClientTable, {}, false, 'bootstrap4', 'default');
Vue.config.productionTip = false 

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
