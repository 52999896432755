import req from '../api/https.js'

export const cmessService ={
    postnew,
    postMsg,
    finditems,
    updata,
    finditem,
    finduslist,
    findusListName,
    dMsg

}


function postnew(obj){
    return req('post', '/infocontorl/postdata',obj).then(rs=>{
        return rs.data
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function postMsg(obj){
    return req('post', '/infocontorl/postMsg',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function dMsg(id){
    return req('delete', '/infocontorl/dMsg/'+id).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    }) 
}

function finditems(id,pid){
    return req('get', '/infocontorl/Findlslist/'+id+'/'+pid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function updata(obj){
    return req('put','/infocontorl/updata',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function finditem(id){
    return req('get', '/infocontorl/finditem/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function finduslist(id){
    return req('get', '/user/list/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findusListName(id,name){
    return req('get', '/user/listName/'+id+'/'+name).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}



