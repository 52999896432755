import {mapState,mapMutations} from 'vuex';
//import { uid } from 'uid';
export const mixinWebsocket ={
    data(){
        return {
            connection: null
        }
    },
    methods: {
        ...mapMutations('ws', ['setWsNotify','setWsState','setSocket']),
        initWebsocket(){

            if(this.status.token && this.user.data.user.id){
                let wsURL = `${process.env.VUE_APP_WSS}message?id=${this.status.token}&userid=${this.user.data.user.id}`
                this.websock = new WebSocket(wsURL)
                this.websock.onopen = this.websocketonopen;
                this.websock.error = this.websocketonerror;
                this.websock.onmessage = this.websocketonmessage;    
                this.websock.onclose = this.websocketclose; 
                //console.log("websock:",this.websock.readyState)    
                this.setSocket({itme:this.websock})
            }
                   
        },

        websocketonopen(){
            this.setWsState({
                state:true 
            });            
            console.log('ws 連線成功~~');
        },
        websocketonerror(e){
            console.error('ws 連線失敗',e);
        },
        websocketonmessage(e){
            // 後端通知前端，前端取得資料
            let _data = e.data;
            //當有後端資料送到前端，利用vuex存到共用的state
            this.setWsNotify({
                //id:uid(16), 
                data: JSON.parse(_data)
            });
            //console.log('ws 取得資料 : ',this.websock);
            //console.log('ws 取得資料',_data);
        },
        websocketsend(data){
            //前端丟資料  
            //let self = this                     
            if (typeof(data) !== "string") {
                data = JSON.stringify(data)
              }            
            //this.websock.send(data)
            if(this.socket!==null){
                this.socket.send(data)
                //console.log('send data : ',data);
            }
        },
        websocketclose(){
            console.log('ws 關閉連線')
            this.setWsState({
                state:false 
            });
            this.setSocket({itme:null})
        },

        websocketstate(){
            console.log(this.websock.readyState)
        }
    },
    computed:{
        ...mapState('account',['status','user']),
        ...mapState('ws',['wsState','socket']),              
      },    
    
}

