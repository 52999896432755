const state = {
    wsNotify: {},
    wsMess: {},
    wsState:false,
    socket:null,
    wsChang:null,
};

const actions = {}

const mutations ={
    setWsNotify(state, payload){
        state.wsNotify = payload;
    },

    setWsMess(state, payload){
        state.wsMess = payload;
    },

    setWsState(state,payload){
        state.wsState = payload.state
    },

    setSocket(state, payload){
        state.socket = payload.itme
    },

    setChang(state,payload){
        state.wsChang = payload.id
    },

    /*sendMessage(state, payload){
        if(state.socket.url !==undefined){
            state.socket.send(payload)
        }else{
            state.wsState=false
        }
    }*/

    sendMessage(state, payload){
        if (state.socket && state.socket.readyState === WebSocket.OPEN) {
            state.socket.send(payload);
        } else {
            //console.error("WebSocket is not connected.");
            state.wsState = false; // Optionally update wsState to reflect that the socket is not ready
        }
    }


};

const getters = {};

export const ws ={
    namespaced : true,
    state,
    actions,
    mutations,
    getters,
}

/* 因爲將多個 module 併入一個 Vuex Store 時，actions 及 mutations 都是在 root space 中，
所以一般會為了避免 actions / mutations 的命名衝突，會自己加上 namespace  : true
*/