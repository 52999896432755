import req from '../api/https.js'

export const reportService ={
    getAddress,
    getAreas,
    getShip,
}


function getAddress(address){
    return req('get', 'report/myreportAreas/'+address).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function getAreas(obj){
    return req('get', 'report/myreportAddress/'+obj.address+'/'+obj.date).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function getShip(obj){
    return req('get', 'report/myreportShip/'+obj.address+'/'+obj.date).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}
