import Vue from 'vue'
import Vuex from 'vuex'
import { account} from "./modules/account"
import { makeorg } from './modules/makeorg'
import { mact } from './modules/mact'
import { ws  } from './modules/ws'
import { sing  } from './modules/sing'
import CreatePersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account,
    makeorg,
    mact,
    ws,
    sing,
  },
  plugins:[CreatePersistedState({
    Storage: window.localStorage,
    reducer(val){
      return{
        account: val.account,
        ws: val.ws,
        makeorg: val.makeorg,
        sing: val.sing
      }
    }
  })]
})
