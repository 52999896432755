<template>
 <div>
    <b-form @submit.prevent="userLogin">
      <b-form-group
        id="input-group-1"
        label="帳號/Email"
        label-for="input-1"
        description=""
      >
        <b-form-input
          id="input-1"
          v-model="user.email"
          type="email"
          placeholder="請輸入 : 帳號 / Email"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="button" pill variant="secondary" block>送出</b-button>
    </b-form>
  </div>

</template>

<script>
//import { firebaseAuth } from "@/config/firebaseConfig.js";

export default {
  name: 'Forget',
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    userLogin() {
      /*firebaseAuth
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(() => {
          this.$router.push("/admin");
        })
        .catch((error) => {
          alert(error.message);
        });*/
    },
  },
};
</script>