import req from '../api/https.js'

export const messService ={
    finditem,
    finditems,
    upstatus,
    finditemslist,
}


function finditem(id){
    return req('get', '/infomessage/finditem/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}
function finditems(id,pid){
    return req('get', '/infomessage/finditems/'+id+'/'+pid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function finditemslist(id,pid){
    return req('get', '/infomessage/finditemslist/'+id+'/'+pid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function upstatus(obj){
    return req('put','/infomessage/upstatus',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}


