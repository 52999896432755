<template>
  <div>

    <div class="my-carousel" v-if="check">
      <VueSlickCarousel v-bind="settings" :autoplay="true" :autoplaySpeed="3000"   @afterChange="onSlideChange" :style="{'border-radius': '15px','cursor': 'pointer'}">          
      <div v-for="data in arydata" :key="data.id">                                                                         
          <img width="1005" height="480" @click="handleClick(currentIndex)" :src="data.url!==''? data.url:catImg('event_1080x540.png')">                               
      </div> 
      <template #prevArrow="">
          <button class="arrow-btn1">
            <b-icon icon="camera" font-scale="2.5"></b-icon> 
          </button>
        </template>
        <template #nextArrow="">
          <button class="arrow-btn2">
          <b-icon icon="camera" font-scale="2.5"></b-icon>
          </button>
        </template>   
      </VueSlickCarousel>
      <div style="padding:10px;" />
    </div>

  </div>
</template>

<script>
import { actService } from '../_services/'
import VueSlickCarousel from 'vue-slick-carousel'
import * as moment from "moment/moment";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import router from '../router'

const queryData = obj => {
  return actService.GetItemAct4(obj) 
};  

export default {
  name: 'Slickcarousel',
  components: {
    VueSlickCarousel,

  },  
  props: {
    msg: String,
    orid: String,
  },
  data() {
    return {
      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        fade: true,
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        focusOnSelect:false,
        slidesToShow: 1,
        slidesToScroll: 1
      },
      arydata:[],
      check:false,
      currentIndex: 0, // 当前可见的幻灯片索引      
    }
  },
  methods: {
    fetch(params = {}) {
      this.$emit("rload",true);
      this.check=false
      this.arydata=null;        
      queryData({
        ...params,
      }).then(({ data }) => {
        //let i =0              
        setTimeout(() => {
          if(data.data!==null){
          //i = data.data.length
            this.check=true
          }else{
            this.check=false
          }           
          if(data.data!==null){
            this.arydata = this.myPush(data.data);
          } 
          this.$emit("rload",false);
        }, 780) 

      });
    },
    query(rs){
        this.fetch(rs);
    },

    myPush(obj) {
        // 使用 Fisher-Yates 洗牌算法來隨機排列陣列
        for (let i = obj.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * obj.length); // 範圍擴大到整個陣列
            [obj[i], obj[j]] = [obj[j], obj[i]]; // 交換元素位置
        }
        for (let i = obj.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [obj[i], obj[j]] = [obj[j], obj[i]]; // 交換元素位置
        }        
        return obj;
    },    
    
    catImg(imgs) {
      return require(`../assets/${imgs}`);
    },    

    goquery(va1){
        var my_date = new Date();
        var first_date = new Date(my_date.getFullYear(), my_date.getMonth()-1, 1);
        this.query({type:va1,date:this.fdateformat(first_date),states:'Y',limits:6,orgid:this.orid,area:"N"})
    },
    goquery2(va1,va2){
        var my_date = new Date();
        var first_date = new Date(my_date.getFullYear(), my_date.getMonth()-1, 1);
        this.query({type:va1,date:this.fdateformat(first_date),states:'Y',limits:6,orgid:va2,area:"N"})
    },
    goquery3(va1,va2){
        var my_date = new Date();
        var first_date = new Date(my_date.getFullYear(), my_date.getMonth()-1, 1);
        this.query({type:va1,date:this.fdateformat(first_date),states:'Y',limits:6,orgid:"N",area:va2})
    },    

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    getVal(){
      return this.orId
    },
    onSlideChange(index) {
      this.currentIndex = index;
    },
    handleClick(index) {
      const data = this.arydata[index];
      this.golinks(data.fid);
    },        
    golinks(val){      
      router.push({ name: 'Actinfo', params: { id: val }}) // -> /user/123
    }     
  },  
  watch: {
      msg(val){
          if(val===''){
              console.log('這是空值')
          }
          console.log(val)
      }
  },
  mounted(){ 
    //var timeInMs = this.fdateformat(Date.now());
    var my_date = new Date();
    var first_date = new Date(my_date.getFullYear(), my_date.getMonth()-1, 1);
    this.query({type:'null',date:this.fdateformat(first_date),states:'Y',limits:6,orgid:this.orid,area:"N"})
    //this.query({type:'null',date:timeInMs,states:'Y',limits:4})
  }   

}
</script>

<style scoped>
    .slick-slider {
        margin:0px auto;
        padding:0px;
        color: white;
        max-width: 1005px;     
    }

    .slick-slider img {
        border-radius: 15px;
        max-width: 100%;
        height: auto; 
    }
    .slick-prev:before {
      font-size: 45px;
    }

    .slick-next:before {
      font-size: 45px;
    }    

    .arrow-btn1{
        z-index: 7;
        border: none; 
        width: 110px;
    }     

    .arrow-btn2 {
        z-index: 7;
        border: none; 
        width: 110px;
    } 
    .custom-dot{
        z-index: 7;
        border-radius: 25px;
        padding:  3px 2px 3px 2px;
        background-color: #d3cece;
    } 
    
</style>
<style>
    .slick-dots {
        position: absolute;
        margin-bottom: 32px; /* 距离底部的距离，根据需要调整 */
        left: 50%;
        transform: translateX(-50%); /* 水平居中 */
        z-index: 100; /* 确保在图片上方 */
        background:hsla(167, 33%, 81%, 0.7);
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .slick-prev:before, 
    .slick-next:before {
      color: rgb(131, 172, 175); /* 修改为你想要的颜色 */
    }    
</style>