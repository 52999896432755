import req from '../api/https.js'

export const lineService = {
    createmsg,
    finditems,
    deletemsg,
    savemsg,
    tomsgs,
    findhistory,
}


function createmsg(obj){
    return req('post','/linemsg/create',obj).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function finditems(obj){
    return req('get', '/linemsg/finditem/'+obj.page+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findhistory(obj){
    return req('get', '/linemsg/findhistory/'+obj.page+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function deletemsg(obj){
    return req('delete', '/linemsg/deletemsg/'+obj).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function savemsg(obj){
    return req('put','/linemsg/update',obj).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function tomsgs(obj){
    return req('post','/linemsg/tomsgs',obj).then(rs=>{
        return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}