import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/remind',
    name: 'Remind',
    component: () => import('../views/Remind.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    name:'Temporary',
    path:'/temporary/:id',
    component:() => import('../auth/TemporaryPage.vue'),  
  },    
  {
    path: '/replace',
    name: 'Replace',
    component: () => import('../auth/Replaces.vue')
  }, 
  {
    name:'Reports',
    path:'/reports',
    component:() => import('../myacts/Reports.vue'),
  },   
  {
    path: '/makact',
    name: 'Makact',
    component: () => import('../act/Act.vue')
  },
  {
    name:'Actinfo',
    path:'/act_info/:id',
    component:() => import('../actinfo/Actinfo.vue'),  
  },  
  {
    name:'Regjoin',
    path:'/reg_join/:id/:type',
    component:() => import('../regform/Regjoin.vue'),
  },  
  {
    name:'Myacts',
    path:'/my_acts',
    component:() => import('../myacts/Myacts.vue'),
  },   
  {
    name:'Myscore',
    path:'/my_score',
    component:() => import('../myacts/Myscore.vue'),
  }, 
  {
    name:'ActTmp',
    path:'/Act_tmp/:id',
    component: () => import('../act/Act_tmp.vue')
  },      
  {
    name:'Actedite',
    path:'/act_edite/:id/:edit',
    component:() => import('../actinfo/Actedite.vue'),
  },     
  {
    name:'Myorg',
    path:'/my_info',
    component:() => import('../myorg/Myorg.vue'),
  }, 
  {
    name:'Outorg',
    path:'/out_info/:id/:type',
    component:() => import('../myorg/Outorg.vue'),
  },  
  {
    name:'Myorder',
    path:'/my_order/:id/:type',
    component:() => import('../myacts/Myorder.vue'),
  }, 
  {
    name:'Query_center',
    path:'/query_center',
    component:() => import('../query/Querys.vue'),    
  },

  {
    path: '/center',
    name: 'Center',
    component: () => import('../center/Center.vue'),   
    beforeEnter: (to, from, next) => {
      const defaultval = VueCookies.get('defaultval')
        if(defaultval==null){
          next('/remind')
        }else{
          if(defaultval.name!==null){
            next()
          }else{
            next('/remind')
          }
        }
    },    
    children:[
      {
        name:'Center',
        path:'center_infobar',
        component:() => import('../center/components/Center_infobar.vue'),
      },
      {
        name:'Info_center',
        path:'Info-center',
        component:() => import('../center/components/Info_center.vue'),
      },      
      {
        name:'Info_detail',
        path:'Info-detail/:id',
        component:() => import('../center/components/Info_detail.vue'),
      },
      {
        name: 'Info_detail',
        path: 'Info-detail',
        redirect: 'info-detail/1'
      },  
      {
        name: 'Info_create',
        path: 'Info-create',
        component:() => import('../center/components/Info_contorl.vue'),
      },   
      {
        name:'Info_contorl_detail',
        path:'Info-contorl-detail/:id',
        component:() => import('../center/components/Info_contorl_detail.vue'),
      },        
      
      {
        name:'Record_detail',
        path:'Record-detail/:id',
        component:() => import('../center/components/controlbulletin/Record_detail.vue'),
      }, 
      
      {
        name:'Verify_actlist',
        path:'Verify-actlist',
        component:() => import('../center/controact/Verify_actlist.vue'),
      }, 

      {
        name:'Act_my',
        path:'Act-my',
        component:() => import('../center/controact/Act_my.vue'),
      }, 

      {
        name:'Act_date',
        path:'/Act-date/:id',        
        component:() => import('../center/controact/Act_date.vue'),
      },  

      {
        name:'Act_details',
        path:'/Act_details',        
        component:() => import('../center/controact/Act_details.vue'),
      },      
      
      {
        name:'Line_control',
        path:'Line-control',
        component:() => import('../center/line/Line_control.vue'),
      },   
      
      {
        name:'Line_report',
        path:'Line-report',
        component:() => import('../center/line/Line_report.vue'),
      },   
      
      {
        name:'Check_orglist',
        path:'Check-orglist',
        component:() => import('../center/contorcheck/Check_orglist.vue'),
      }, 
      
      {
        name:'Type_control',
        path:'Type-control',
        component:() => import('../center/components/types/Type_control.vue'),
      },  
      
      {
        name:'Editorg',
        path:'Editorg',
        component:() => import('../center/edit/Editorg.vue'),
      },

      {
        name:'Partner',
        path:'/partner',
        component:() => import('../center/edit/Partner.vue'),
      },   
      
      {
        name:'Org_generate',
        path:'/Org_generate',
        component:() => import('../center/edit/Org_generate.vue'),
      },    
      
      {
        name:'Men_generate',
        path:'/Men_generate',
        component:() => import('../center/edit/Men_generate.vue'),
      },
      
      {
        name:'Joinlist',
        path:'/Join_list/:id',
        component:() => import('../center/joinlist/Joinlist.vue'),
      },      
      
    ]
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../auth/Setting.vue')
  },
  {
    path: '/user/edit',
    name: 'Edit',
    component: () => import('../user/Edit.vue')
  },     
  {
    path: '/auth/:token/post/:postid/:id',
    name: 'Auth',
    component: () => import('../auth/Auth.vue')
  },
  {
    path: '/lineJoin/:v1/:v2/:v3/:v4/:v5',
    name: 'LineJoin',
    component: () => import('../auth/LineJoin.vue')    
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
    //component: () => import('../auth/Login.vue'),
  },
  {
    path: '/myline',
    name: 'Myline',
    component: () => import('../myline/Myline.vue')
    //component: () => import('../auth/Login.vue'),
  },  

  {
    path: '/mylove',
    name: 'Mylove',
    component: () => import('../myline/Mylove.vue')
    //component: () => import('../auth/Login.vue'),
  },  

  {
    path: '/myscoredata',
    name: 'MyScoreData',
    component: () => import('../myline/MyScoreData.vue')
    //component: () => import('../auth/Login.vue'),
  },  

  {
    path: '/mydoing',
    name: 'Mydoing',
    component: () => import('../myline/Mydoing.vue')
    //component: () => import('../auth/Login.vue'),
  },  

  {
    name:'Myapply',
    path:'/line_apply/:id',
    component:() => import('../myline/Myapply.vue'),  
  },    
  

  {
    path: '/lineorg',
    name: 'Lineorg',
    component: () => import('../lineorg/Lineorg.vue')
  },

  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*router.beforeEach((to, from, next) => {
  const defaultval = VueCookies.get('defaultval')
  if(to.path=='/center/center_infobar'){
    if(defaultval.name!==null){
      next()
    }else{
      next('/makact')
    }
  }else{
    next()
  }
})*/

export default router
