import req from '../api/https.js'

export const userService ={
    login,
    loginline,
    loginfb,
    logout,
    checkAcct,
    upprofile,
    upload,
    checkEmail,
    regist,
    registlist,
    getMylist,
    findaddress, 
    findusid,
    putUser,
    myLogin,
    myPutLogin,
    myGroups,
    queryGroupName,
    postGroup,
    deleteGroup,
}

function login(username,password){
    const auth = { username: username, password: password };
    return req('post','user/login',auth).then(user=>{
        localStorage.setItem('loginMsg', user.data.msg);
        if(user.data.data.token) {
            //localStorage.clear();
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(user.data));
        }
        return user.data         
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })
}

function loginline(token){
    const auth = {token: token}
    return req('post', 'user/loginline',auth).then(user=>{
        if(user.data.data.token){
            localStorage.setItem('user',JSON.stringify(user.data))
        }
        return user.data
    }).catch(error=>{
        this.logout()
        return Promise.reject(error)
    })
}

function loginfb(token){
    const auth = {token: token}
    return req('post', 'user/loginfb',auth).then(user=>{
        if(user.data.data.token){
            //JSON.stringify()：將資料轉為 JSON 格式的字串。
            localStorage.setItem('user',JSON.stringify(user.data))
        }
        return user.data
    }).catch(error=>{
        this.logout()
        return Promise.reject(error)
    })
}

function checkAcct(aname){
    return req('get', 'user/chaccount/'+aname).then(rs=>{
        return rs.data
    }).catch(error=>{
        this.logout()
        return Promise.reject(error)
    })
}

function findusid(obj){
    return req('get', 'user/'+obj.uid).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function checkEmail(aname){
    return req('get', 'user/chmail/'+aname).then(rs=>{
        return rs.data
    }).catch(error=>{
        this.logout()
        return Promise.reject(error)
    })
}

function upprofile(userdata){
    return req('put','user/upprofile',userdata).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function putUser(obj){
    return req('put','user/upuser',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function regist(userdata){
    return req('post','user/register',userdata).then(rs=>{ 
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    }) 

}

function registlist(obj){
    return req('post','user/registerlist',obj).then(rs=>{ 
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    }) 

}


function postGroup(obj){
    return req('post','user/postGroup',obj).then(rs=>{ 
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    }) 

}

function getMylist(obj){
    return req('get', '/user/getMylist/'+obj.id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function upload(file){
    //console.log(file)
    var formData = new FormData();
    formData.append("imgfile", file);    
    return req('post','user/image',formData).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })      
}

function logout(){
    localStorage.removeItem('user');
}

function findaddress(obj){
    return req('get', '/user/address/'+obj.page+'/'+obj.address).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function myGroups(obj){
    return req('get', '/user/myGroups/'+obj.id).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function queryGroupName(obj){
    return req('get', '/user/queryGroupName/'+obj.id+'/'+obj.name).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function deleteGroup(obj){
    return req('delete', '/user/deleteMyGroup/'+obj.id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function myLogin(auth){
    return req('post','user/myLogin',auth).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })
}


function myPutLogin(auth){
    return req('put','user/myLogin',auth).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })
}