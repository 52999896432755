<template>
    <div class="container-xxl">
        <h6><router-link v-if="uId==''" :to="{name:'Info_center'}">最新公告&ensp;>&ensp;</router-link>公告明細&ensp;
            <b-icon v-show="obj.typ=='alert'" icon="bell-fill" variant="warning" font-scale="1.5" ></b-icon>
            <b-icon v-show="obj.typ=='mess'" icon="chat-quote-fill" variant="success" font-scale="1.5"></b-icon>
        </h6>
      <b-card :header="obj.title">
      <blockquote class="blockquote mb-0">
       <div style="text-align:left;font-size: 16px;">刊登日期 : {{fdateformat(obj.created_at)}} | 發文者 : {{obj.fname}}</div>
        <p></p>
        <div class="divcss" v-html="obj.content" style="text-align:left;font-size: 16px;" ></div>   
              <b-card  v-if="detail!==null"
                :img-src="detail.url !== '' ? detail.url : mainImg('event_540.png')"
                img-alt="Image"
                img-top                
                :title="detail.actitle"
                :style="{ 'margin': '5px', 'width': '100%',  'border-radius': '20px', 'box-shadow': '0 4px 8px 0 rgba(0,0,0,0.2),' }"
                class="card-transition card-container mb-3">                       
                <b-list-group flush class="text-left">
                  <b-list-group-item>主辦單位: {{ detail.name }}</b-list-group-item>
                  <b-list-group-item>活動類別: {{ detail.atitle }}</b-list-group-item>
                  <b-list-group-item>活動地點: {{ detail.address }}</b-list-group-item>
                  <b-list-group-item>活動內容: <div v-html="detail.content" /></b-list-group-item>
                </b-list-group>
              </b-card>
      </blockquote>
    </b-card>
    </div>
</template>
<script>
import { messService,macService } from '../../_services/'
import * as moment from "moment/moment";

const queryData = params => {
  return messService.finditem(params.page) 
};
const queryMact = params => {
  return macService.macfindgetOrg(params.id) 
};

export default{
    name: 'Info_detail',   
    props:{
          ids:{
              type:String
          },         
        },      
    data(){
        return{
            uId:{String},
            obj:[],
            detail:null,
        }
    },
  methods: {

    async fetch(params = {}) {
      try {
        const { data } = await queryData({ ...params });
        this.obj = data;
        if (this.obj.kinds == 1) {
          this.detail = await queryMact({id:this.obj.oid});
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    doSearch() {
        if(this.ids!==""){
          this.uId = this.ids
        }else{
          this.uId = this.$route.params.id
        }                 
        try {
          this.fetch({page:this.uId});  
        } catch (error) {
          console.error('Error fetching data:', error);
        }                      
    }


  },
  watch: {
      '$route.params.id'() {
          this.doSearch();
      }
  },  

  mounted(){
        if(this.ids!==undefined){
          this.uId = this.ids
        }else{
          this.uId = this.$route.params.id
        }
        try {
          this.fetch({page:this.uId});  
        } catch (error) {
          console.error('Error fetching data:', error);
        }          
  },    

}
</script>
<style>
.divcss img{
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
} 
</style>