import VueCookies from 'vue-cookies'


const state = {
        singrecords: [],
        singsupplier:[],
      };

const actions = {

};

const getters ={
    getAccount(state){
        return state.user
    }
}

const mutations ={
    singini(state){
        VueCookies.remove('record')
        state.singrecords = []
        VueCookies.set('record',state.singrecords);
    },
    singremov(state){
        VueCookies.remove('record')
        state.singrecords =[]
    },

    singadd(state,obj){
        state.singrecords.push(obj)
        VueCookies.set('record',state.singrecords);
    },  
    
    singrmove(state,obj){
        state.singrecords=state.singrecords.filter(rs=>rs!==obj)
        VueCookies.set('record',state.singrecords);
    },  
    
    suppliergini(state){
        VueCookies.remove('records')
        state.singsupplier = []
        VueCookies.set('records',state.singsupplier);
    }, 
    supplierremov(state){
        VueCookies.remove('records')
        state.singsupplier =[]
    },

    supplieradd(state,obj){
        state.singsupplier.push(obj)
        VueCookies.set('records',state.singsupplier);
    },  
    
    suppliermove(state,obj){
        state.singsupplier=state.singsupplier.filter(rs=>rs.id!==obj.id || rs.uid!==obj.uid )
        VueCookies.set('records',state.singsupplier);
    },       
}

export const sing ={
    namespaced : true,
    state,
    actions,
    mutations,
    getters
}