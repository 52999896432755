//import router from "../router/index";
import { message } from 'ant-design-vue'
import router from '../router'
import store from "../store/index"
import Vue from 'vue'

message.config({
    duration: 2, // 提示時常單位為s
    top: '100x', // 距離頂部的位置
    maxCount: 3 // 最多顯示提示資訊條數(後面的會替換前面的提示)
  })

export const tip = msg =>{
    message.info(msg ? msg : 'Error')

}

export const toLogin =() =>{
    //this.$router.replace({ name: "user-view", params: {id:"123"}, query: {q1: "q1"} })
    //let names = store.state("account/targetpage")
    let names = store.state.account.targetpage
    //console.log("targetpage:",names)
    if(names!==null){
        router.replace({
            name: names,    
        }).catch(err=>{err});            
    }else{
        router.replace({
            name: 'Login',    
            /*params:{id:"0"},    
            query:{
                redirect : router.currentRoute.fullPath
            }*/
        }).catch(err=>{err});
    }

}

export const to403Page = () =>{
    router.replace(
        {
            name: '403'
        }
    ).catch(err=>{err})
}

export const checktokens =(val) =>{
    let verify= Vue.$jwt.decode(String(val),"johnnyisgoodman") 
    return verify
}