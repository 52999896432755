<template>
 <div>
    <b-form>
      <b-button type="button" variant="link" @click="linelogin">  
        <img alt="Vue logo" src="../assets/lineloginnew.png">
      </b-button>
    </b-form>
  </div>

</template>

<script>
import {mapState,mapActions} from 'vuex';
export default {
  name: 'Linelogin',
  data() {
    return {
        errors:'',
        mtyps:'error',
        display:false,
    };
  },
  methods: {
    ...mapActions('account',['login']),

    linelogin(){
      //window.location=process.env.VUE_APP_URL +'gotoauthOpenIDpage'
      let target = 'Home'  
      if(this.targetpage){
        target = this.targetpage
      }
      let  temp_form = document.createElement("form");
           temp_form.action = process.env.VUE_APP_URL +'gotoauthOpenIDpage';
           //temp_form.action = process.env.VUE_APP_URL +'gotoauthpage'; 
           temp_form.target = "_self";
           temp_form.method = "post";      
           temp_form.style.display = "none";
      let  opt = document.createElement("input"); 
           opt.type = "text";
           opt.name = "chatbot";
           opt.value = target
           temp_form.appendChild(opt);    
           document.body.appendChild(temp_form);
           // 提交表單      
           temp_form.submit();   
    },

  },
    computed: {
      ...mapState('account',['status','targetpage']),
    },


};
</script>