<template>
  <div id="itemlist">
        <div 
            v-if="show=='1'"
          style="
            max-width:1024px;
            margin-top:-0.3rem;
            margin-left:0.5rem;                
            border: 0px solid #333232; /* 黑色外框 */
            border-radius: 50px; /* 外框倒角 */
            padding: 10px; /* 內部邊距 */" :class="winsize.width>=720? 'text-left':'text-center'" >
            <span style="font-family:'Microsoft JhengHei', '微軟正黑體', 'Microsoft YaHei', sans-serif; 
            font-size: 22px; 
            font-weight: bold;" v-text="'推薦活動'" />
        </div>    
        <transition-group name="card" tag="div">         
            <a-row v-if="item.length>0" type="flex" :justify="winsize.width>=720? 'start':'center'" :key="`row-${rowId}`">
                <a-col v-for="data in item" :key="data.id">
                    <b-card
                        :title="data.actitle.length>13? data.actitle.substring(0,13)+'...':data.actitle"
                        :img-src="data.url!==''? data.url:mainImg('event_1080x540.png')"
                        img-alt="Image"
                        img-top
                        img-class="card-img-top"
                        tag="article"
                        :style="{ 'cursor':'pointer', 'margin': '10px','width':'20rem', 'max-width' : winsize.width>=720? '20rem':'100%','border-radius':'20px', 'box-shadow':' 0 4px 8px 0 rgba(0,0,0,0.2)' }"
                        class="card-transition card-container"
                    >
                        <b-card-text>                    
                            <div class="card-section">
                              <span v-text="'活動類別 :'" /> 
                              <a-avatar :size="24" :src="catImg(geturl(data.theme))"  />
                              <b-icon icon="geo-alt-fill" style="margin-left: 8px;margin-right: 3px;"  font-scale="1.2"></b-icon>
                              <span v-text="data.address.substring(0,6)+'...'" />
                            </div>
                            <div class="card-section">活動起訖 : <span v-text=" fdateformat(data) " /></div>
                            <hr style="margin: 0.1rem;" class="shadow">
                        </b-card-text>
                        <div class="rightbtn" @click="golinks(data.id)">
                            了解詳情
                            <b-icon style="margin:3px;" icon="zoom-in" aria-hidden="true"></b-icon>
                        </div>
                    </b-card>
                    <hr v-if="winsize.width>=720" class="hr-dotted" style="margin-top: 1.5rem;">
                </a-col>                  
            </a-row>
        </transition-group>
        <div v-if="item!==undefined" style="margin:2rem;"> 
          <span v-if="item.length==0" tip="無活動資料, 請確認..."></span>          
        </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import MY_JSON from '../assets/json/optiondata.json'
import router from '../router'
export default {
  name: 'Actitemlist',
  props: {
    msg: String,
    item: Array,
    winsize: Object,
    kinds:String,
    show:String,
  },
  data() {
    return {
      myJson: MY_JSON,
      rowId:1,
      window: {
            width: 0,
            height: 0
        }   
    }
  }, 
  methods: {
    fdateformat(val){
      if(val.stime==undefined){
        return moment(val.sdate).format("YYYY-MM-DD HH:mm") +'~'+ moment(val.edate).format("MM-DD HH:mm") ; 
      }
      else{
        return moment(val.sdate+" "+val.stime).format("YYYY-MM-DD HH:mm") +'~'+ moment(val.edate+" "+val.etime).format("MM-DD HH:mm") ; 
      }
    },
    catImg(imgs) {
            return require(`../assets/img/${imgs}`);
    }, 
    mainImg(imgs) {
      return require(`../assets/${imgs}`);
    },     
    geturl(theme){
        let url = this.myJson.find(rs=>rs.value==theme).url
        return url
    }, 
    getxt(val){
        return val.substring(1, 32)
    },  
    golinks(val){ 
      if(this.kinds=='new') {
        const routeData = this.$router.resolve({ name: 'Actinfo', params: { id: val }});
        window.open(routeData.href, '_blank');
        //router.push({ name: 'Actinfo', params: { id: val }})
      }else{
        router.push({ name: 'Actinfo', params: { id: val }}) 
      }  
    } 
  },   
  created() {
  }, 
  
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #itemlist {
        margin:0px auto;
        padding:0px;
        padding-left:0.1rem;
        max-width: 1024px;       
 }
 .card-title{
   text-align: left;
   font-size:18px;
   word-break: "break-word";
 }
 .card-text{
    font-size:14px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.card-img-top{
  max-height: 150px;
  max-width: 320px;
}
.card-body{
    max-width: 320px;
}
.rightbtn {
    background: linear-gradient(90deg, #65b5f7 0%, #4ea0e4 35%, #418fd0 100%);
    border-radius: 15px;
    width:100%; 
    height:30px; 
    float:left; 
    color: #ecf2f7;
    font-weight:bold;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin-top:-0.4rem;
    line-height:25px;
}

.rightbtn:hover {
    background: linear-gradient(90deg, #7dc3fd 0%, #62b5f8 35%, #418fd0 100%);
    cursor: pointer;
}

.card-title{
  height: 35px;
}


.card-transition {
    transition: box-shadow .3s ease-in-out, transform .3s ease-in-out;
}

.card-transition:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transform: translateY(-5px);
}

.card-section {
    margin-bottom: 5px;
}

.rightbtn {
    cursor: pointer;
    color: #ffffff; /* Bootstrap primary color */
    text-decoration: none;
    background-color: transparent;
    transition: color .15s ease-in-out;
}

.rightbtn:hover {
    font-weight: bold;
    color: #ffffff; /* Bootstrap primary color darker */
    text-shadow: rgb(151, 148, 148) 0.1em 0.1em 0.2em;
}

/* 過渡效果 */
.card-enter-active, .card-leave-active {
    transition: opacity .5s, transform .5s;
}
.card-enter, .card-leave-to {
    opacity: 0;
}
.hr-dotted {
    border: 0;
    height: 1px;
    background: transparent;
    position: relative;
}

.hr-dotted:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-image: radial-gradient(circle, #333 20%, transparent 20%); /* 创建点状效果 */
    background-size: 10px 3px; /* 点的大小和间距 */
    background-repeat: repeat-x;
}

.card-container {
    overflow: hidden; /* 确保图片放大时不会超出容器 */
    position: relative; /* 相对定位，为图片放大做准备 */
}

.card-img-top {
    transition: transform .3s ease-in-out; /* 平滑过渡效果 */
    display: block; /* 确保图片是块级元素 */
    width: 100%; /* 让图片宽度适应容器 */
    height: auto; /* 保持图片的原始宽高比 */
}

.card-container:hover .card-img-top {
    transform: scale(1.08); /* 鼠标悬停时放大图片 */
}

hr.shadow {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #fff, #a7a4a4, #fff);
    box-shadow: 0 1px 2px #bbb;
}

.card-title {
   font-weight: bold;
   font-size: 19px;
   height:20px;
}

</style>
