import req from '../api/https.js'

export const actService ={
    finditem,
    finditems,
    upstatus,
    finditemslist,
    findchecklist,
    findlistly,
    findConfrimly,
    FindCFMstatus,
    findUconfrimly,
    findactlist,
    putactype,
    putcancel,
    getactlist,
    getactlists,
    getactlistall,
    getactlistall_bak,
    getactItems,
    findmyact,
    findmyactaid,
    findmyactbyDate,
    findmyactbyDate2,
    findActIdes,
    findActdetail,
    delActclass,
    delAct,
    delMan,
    inertMan,
    findactpage,
    Findactpagebystate,
    Findactpagebystatetmp,
    addAct,
    upAct,
    getItemAct,
    GetItemAct4,
    findActdetail2,
    MyOrgScore,
    MyOrgScoreDetail,
    MyOrgDataScoreDetail,
    GetactItemsByOrg,
}


function findmyact(id){
    return req('get', '/activity/Findmyact/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findmyactbyDate(obj){
    return req('get', '/activity/findmyactbyDate/'+obj.id+'/'+obj.firstDate+'/'+obj.lastDate).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 


function findmyactbyDate2(obj){
    return req('get', '/activity/findmyactbyDate2/'+obj.uid+'/'+obj.id+'/'+obj.firstDate+'/'+obj.lastDate).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function findmyactaid(obj){
    return req('get', '/activity/Findmyactaid/'+obj.id+'/'+obj.aid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function finditem(id){
    return req('get', '/activity/Findactlis/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findchecklist(id,modes){
    return req('get', '/activity/Findchecklist/'+id+"/"+modes).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}  

function findUconfrimly(obj){
    return req('get', '/activity/FindUconfrimly/'+obj.page+'/'+obj.uid+"/"+obj.modes).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findActdetail(id,aid){
    return req('get', '/activity/FindActdetail/'+id+"/"+aid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function findActdetail2(id,aid){
    return req('get', '/activity/FindActdetail2/'+id+"/"+aid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function delActclass(id,aid,fid){
    return req('delete', '/activity/DelActclass/'+id+"/"+aid+"/"+fid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 


function delAct(id){
    return req('delete', '/activity/DelAct/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function delMan(id,aid,fid,uid,roles){
    return req('delete', '/activity/delMan/'+id+"/"+aid+"/"+ fid+"/"+ uid+"/"+roles).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function findlistly(id){
    return req('get', '/activity/Findlistly/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 



function findConfrimly(obj){
    return req('get', '/activity/FindConfrimly/'+obj.page+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function FindCFMstatus(obj){
    return req('get', '/activity/FindCFMstatus/'+obj.page+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findactlist(id){
    return req('get', '/activity/Findactlist/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function findActIdes(id){
    return req('get', '/activity/FindActIdes/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}
function getactItems(obj){
    return req('get', '/activity/getactItems/'+obj.title+'/'+obj.address+'/'+obj.type+'/'+obj.date+'/'+obj.states+'/'+obj.page+'/'+obj.size+'/'+obj.kinds).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function GetactItemsByOrg(obj){
    return req('get', '/activity/getactItemsByOrg/'+obj.title+'/'+obj.address+'/'+obj.type+'/'+obj.date+'/'+obj.states+'/'+obj.page+'/'+obj.size+'/'+obj.kinds).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function getactlist(obj){
    return req('get', '/activity/getactlist/'+obj.type+'/'+obj.date+'/'+obj.states+'/'+obj.limits).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function getactlists(obj){
    return req('get', '/activity/getactlists/'+obj.page+'/'+obj.size+'/'+obj.type+'/'+obj.date+'/'+obj.states).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error) 
    })
} 

function getItemAct(obj){
    return req('get', '/activity/getItemAct/'+obj.page+'/'+obj.size+'/'+obj.type+'/'+obj.date+'/'+obj.states+'/'+obj.orgid+'/'+obj.area).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error) 
    })
} 


function GetItemAct4(obj){
    return req('get', '/activity/GetItemAct4/'+obj.type+'/'+obj.date+'/'+obj.states+'/'+obj.limits+'/'+obj.orgid+'/'+obj.area).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error) 
    })
} 

function getactlistall(obj){
    return req('get', '/activity/getactlistall/'+obj.page+'/'+obj.states+'/'+obj.size).then(rs=>{    
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function getactlistall_bak(obj){
    return req('get', '/activity/getactlistall/'+obj.page+'/'+obj.states+'/'+obj.size+'/'+obj.type).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}


function putactype(obj){
    return req('put','/activity/Putactype',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })     
}

function putcancel(obj){
    return req('put','/activity/Putcancel',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })     
}

function finditems(id,pid){
    return req('get', '/infomessage/finditems/'+id+'/'+pid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function finditemslist(id,pid){
    return req('get', '/infomessage/finditemslist/'+id+'/'+pid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function upstatus(obj){
    return req('put','/infomessage/upstatus',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}


function inertMan(obj){
    return req('post','activity/inertMan',obj).then(rs=>{        
            return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
} 

function addAct(obj){
    return req('post','activity/inertAct',obj).then(rs=>{        
            return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function upAct(obj){
    return req('post','activity/upAct',obj).then(rs=>{        
            return rs
    }).catch(error =>{
        return Promise.reject(error);
    })
}

function findactpage(obj){
    return req('get', '/activity/findactpage/'+obj.page+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function Findactpagebystate(obj){
    return req('get', '/activity/Findactpagebystate/'+obj.page+'/'+obj.uid+'/'+obj.state).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function Findactpagebystatetmp(obj){
    return req('get', '/activity/Findactpagebystatetmp/'+obj.page+'/'+obj.uid).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function MyOrgScore(obj){
    return req('get', '/activity/MyorgScore/'+obj.id+'/'+obj.year+'/'+obj.month).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function MyOrgScoreDetail(obj){
    return req('get', '/activity/MyOrgScoreDetail/'+obj.id+'/'+obj.year+'/'+obj.month).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
} 


function MyOrgDataScoreDetail(obj){
    return req('get', '/activity/MyOrgDataScoreDetail/'+obj.id+'/'+obj.year+'/'+obj.month).then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
} 


