import req from '../api/https.js'

export const typeService ={
    postnew,
    postdetail,
    finditems,
    updata,
    putdata,
    remtype,
    finditem,
    findtyplist,
    deldata,
    findTypeList,

}


function postnew(obj){
    return req('post', '/typeitem/postdata',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}
function postdetail(obj){
    return req('put', '/typeitem/postdetail',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function finditems(id){
    return req('get', '/typeitem/Findlslist/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}     

function findTypeList(){
    return req('get', '/typeitem/findTypeList').then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
} 

function deldata(id){
    return req('delete', '/typeitem/typedel/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function updata(obj){
    return req('put','/typeitem/updata',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function putdata(obj){
    return req('put','/typeitem/putdata',obj).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}

function remtype(id){
    return req('delete','/typeitem/remtype/'+id).then(rs=>{
        return rs
    }).catch(error =>{
        this.logout()
        return Promise.reject(error);
    })    
}


function finditem(id){
    return req('get', '/typeitem/finditem/'+id).then(rs=>{
        return rs
    }).catch(error=>{
        return Promise.reject(error)
    })
}

function findtyplist(){
    return req('get', '/typeitem/findtyplist').then(rs=>{
        return rs.data
    }).catch(error=>{
        return Promise.reject(error)
    })
}